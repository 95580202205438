<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @fetchData="fetchData"
    />
    <el-dialog
      append-to-body
      class="common-dialog"
      :close-on-click-modal="false"
      :title="title"
      top="10vh"
      :visible.sync="dialogFormVisible"
      width="100vh"
      @close="close"
    >
      <div v-if="dialogFormVisible" class="abow_dialog">
        <addTreeTreeItemEdit
          v-if="dialogCom == 'TreeItem'"
          ref="TreeItem"
          :userPhone="userPhone"
        />
        <addTreeVarietyEdit
          v-if="dialogCom == 'TreeVariety'"
          ref="TreeVariety"
          :userPhone="userPhone"
        />
        <addProjectEdit
          v-if="dialogCom == 'Project'"
          ref="Project"
          :userPhone="userPhone"
        />
        <addReportEdit v-if="dialogCom == 'Report'" :userPhone="userPhone" ref="Report" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryUserList, delDataItem } from "@/project/eztree/baseApi/UserApi";
const statusOptions = [
  { label: "删除", value: "-1" },
  { label: "注销", value: "0" },
  { label: "正常", value: "1" },
];
export default {
  name: "User",
  components: {
    addTreeTreeItemEdit: () => import("../TreeItem/index"),
    addTreeVarietyEdit: () => import("../TreeVariety/index"),
    addProjectEdit: () => import("../Project/index"),
    addReportEdit: () => import("../Report/list"),
  },
  data() {
    return {
      visited: null,
      dialogCom: "",
      userPhone: "",
      title: "",
      dialogFormVisible: false,

      queryForm: {
        start_time: "",
        end_time: "",
      },
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "手机号",
        },
        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
      process: process.env,
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "苗店手机号",
          prop: "shop_phone",
          width: "160px",
        },
        {
          label: "苗店名称",
          prop: "shop_name",
          width: "160px",
        },
        {
          label: "苗木个数",
          key: "item_count",
          width: "120px",
          align: "center",
          render: function (h, params) {
            return h(
              "el-button",
              {
                attrs: {
                  type: "text",
                  params: params.row,
                },
              },
              params.row.item_count || "-"
            );
          },
        },

        {
          label: "苗单个数",
          prop: "variety_count",
          render: function (h, params) {
            return h(
              "el-button",
              {
                attrs: {
                  type: "text",
                  params: params.row,
                },
              },
              params.row.variety_count || "-"
            );
          },
        },
        {
          label: "项目个数",
          prop: "project_count",
          render: function (h, params) {
            return h(
              "el-button",
              {
                attrs: {
                  type: "text",
                  params: params.row,
                },
              },
              params.row.project_count || "-"
            );
          },
        },
        {
          label: "报告个数",
          prop: "report_count",
          render: function (h, params) {
            return h(
              "el-button",
              {
                attrs: {
                  type: "text",
                  params: params.row,
                },
              },
              params.row.report_count || "-"
            );
          },
        },
        {
          label: "创建时间",
          width: "180px",
          prop: "created_at",
        },
        {
          label: "最后活跃时间",
          width: "180px",
          prop: "last_active_at",
        },
        // {
        //   label: "状态",
        //   prop: "status",
        //   width: 120,
        //   render: (h, params) => {
        //     return h(
        //       "el-tag",
        //       {
        //         attrs: {
        //           type:
        //             params.row.status == "0"
        //               ? "danger"
        //               : params.row.status == "1"
        //               ? "success"
        //               : "",
        //           effect: "plain",
        //         },
        //       },
        //       params.row.status == "0"
        //         ? "封停"
        //         : params.row.status == "1"
        //         ? "正常"
        //         : "已删除"
        //     );
        //   },
        // },

        {
          label: "操作",
          width: 280,
          render: (h, params) => {
            return h(
              "div",
              {
                style: {},
              },
              [
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.viewItemData(params.row, "TreeItem", "苗木列表");
                      },
                    },
                  },
                  "苗木列表"
                ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.viewItemData(params.row, "TreeVariety", "苗单列表");
                      },
                    },
                  },
                  "苗单列表"
                ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.viewItemData(params.row, "Project", "项目列表");
                      },
                    },
                  },
                  "项目列表"
                ),
                h(
                  "el-button",
                  {
                    attrs: {
                      type: "text",
                      params: params.row,
                    },
                    on: {
                      ...this.$listeners,
                      click: () => {
                        this.viewItemData(params.row, "Report", "报告列表");
                      },
                    },
                  },
                  "报告列表"
                ),
                // params.row.status != "-1"
                //   ? h(
                //       "el-button",
                //       {
                //         attrs: {
                //           type: "text",
                //           params: params.row,
                //         },
                //         on: {
                //           ...this.$listeners,
                //           click: () => {
                //             this.handleEdit(params.row, "delete");
                //           },
                //         },
                //       },
                //       "删除"
                //     )
                //   : "",
              ]
            );
          },
        },
      ];
      return columns;
    },
  },
  mounted() {
    // socket客户端向服务端通讯，每次访问当前页面，通过visitedServer更新访问量
    // this.$socket.emit("前端用户表：=》visitedServer");
  },
  // sockets: {
  //   // 成功链接的回调
  //   connect: function () {
  //     console.log("前端用户表：》socket connected");
  //   },
  //   // 初始化调用io.controller.visited.server
  //   // CustomVisited 接受的socket.io传递过来的 visited访问量
  //   CustomVisited: function (visited) {
  //     console.log("前端用户表：=》收到了客户端的消息", visited);
  //     this.visited = visited;
  //   },
  // },
  methods: {
    viewItemData(row, flag, action) {
      this.dialogCom = flag;
      this.userPhone = row.shop_phone;
      this.title = action;
      this.dialogFormVisible = true;
    },
    close() {
      this.dialogCom = "";
      this.userPhone = "";
      this.title = "";
      this.dialogFormVisible = false;
    },
    // 初始化
    async initQuery() {
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      queryUserList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          // var phone = 15845621523;  //获取到的电话信息
          // phone = "" + phone;
          // var reg = /(\d{3})\d{4}(\d{4})/; //正则表达式
          // var phone = phone.replace(reg, "$1****$2")

          this.tableData.forEach((ele) => {
            ele.phoneNumber =
              (ele.phone &&
                (ele.phone + "").replace(/^(\d{3})\d{4}(\d{4})$/, "$1****$2")) ||
              "-";
            ele.itemLen = ele.item_info && ele.item_info.length;
            ele.projectLen = ele.project_info && ele.project_info.length;
            ele.varietyLen = ele.variety_info && ele.variety_info.length;
          });
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delDataItem({ id: row.id }).then(({ code, data }) => {
          if (code == "200") {
            this.$baseMessage("删除成功", "success", "vab-hey-message-success");
            this.initQuery();
          }
        });
      });
    },
  },
};
</script>
