import request from '@/utils/request'
// 手机号列表
export function queryDataList (data) {
  return request({
    url: 'admin/phone/list',
    method: 'post',
    data,
  })
}
// 用户列表
export function queryUserList (data) {
  return request({
    url: 'admin/user/list',
    method: 'post',
    data,
  })
}
// 保存
export function saveDataItem (data) {
  return request({
    url: 'admin/user/save',
    method: 'post',
    data,
  })
}
// 详情
export function queryDataDetail (data) {
  return request({
    url: 'admin/user/info',
    method: 'post',
    data,
  })
}
// 删除
export function delDataItem (data) {
  return request({
    url: 'admin/user/del',
    method: 'post',
    data,
  })
}
// 1、正常，0、封停，-1、删除
export function statusDataItem (data) {
  return request({
    url: '/admin/user/change-status',
    method: 'post',
    data,
  })
}
