var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-management-container" },
    [
      _c("nxp-search", {
        attrs: { "config-list": _vm.searchConfigList },
        on: { clickSearch: _vm.clickSearch, resetForm: _vm.resetForm },
      }),
      _c("select-table", {
        attrs: {
          accessories: _vm.nxpTabelAccessories,
          "only-key-id": "id",
          "table-columns": _vm.tableColumns,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "w-theight": 320,
        },
        on: { fetchData: _vm.fetchData },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "common-dialog",
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            title: _vm.title,
            top: "10vh",
            visible: _vm.dialogFormVisible,
            width: "100vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _vm.dialogFormVisible
            ? _c(
                "div",
                { staticClass: "abow_dialog" },
                [
                  _vm.dialogCom == "TreeItem"
                    ? _c("addTreeTreeItemEdit", {
                        ref: "TreeItem",
                        attrs: { userPhone: _vm.userPhone },
                      })
                    : _vm._e(),
                  _vm.dialogCom == "TreeVariety"
                    ? _c("addTreeVarietyEdit", {
                        ref: "TreeVariety",
                        attrs: { userPhone: _vm.userPhone },
                      })
                    : _vm._e(),
                  _vm.dialogCom == "Project"
                    ? _c("addProjectEdit", {
                        ref: "Project",
                        attrs: { userPhone: _vm.userPhone },
                      })
                    : _vm._e(),
                  _vm.dialogCom == "Report"
                    ? _c("addReportEdit", {
                        ref: "Report",
                        attrs: { userPhone: _vm.userPhone },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }